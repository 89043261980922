<template>
  <div class="container" style="max-width: 1000px; margin: 0 auto;">
    <img src="@/assets/images/visicardia.png" alt="Visicardia" style="filter: invert(1)">
    <h1>Visicardia</h1>
    <h3>Terms and Conditions</h3>
  </div>
</template>
<script>
export default {
  name: "Terms",
  created() {
    document.title = "Visicardia :: Terms and Conditions";
  },
  beforeDetroy() {
    document.title = "Visicardia";
  },
};
</script>
<style lang="scss" scoped>
</style>